import React from "react"
import Viewport from "../../components/common/viewport"
import JobOfferContent from "../content/job-offer/job-offer-content"
import SEO from "../seo"

const JobOfferPage = props => {
  const page = props.pageContext.jobOffer

  if (!page) return

  return (
    <Viewport>
      <JobOfferContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default JobOfferPage

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.jobOffer.seoTitle}
    description={pageContext.jobOffer.seoDescription}
    keywords={pageContext.jobOffer.seoKeywords}
  />
)
