import React from "react"
import PropTypes from "prop-types"
import ContentSection from "./content-section"
import JobOffersSection from "./job-offers-section"
import ContactSection from "../../common/contact/contact-section"
import Theme from "../../../theme/theme"

const JobOfferContent = ({ pageContext }) => {
  return (
    <>
      <ContentSection jobOffer={pageContext.jobOffer} />
      {pageContext.jobOffer.contact && (
        <ContactSection
          content={pageContext.jobOffer.contact}
          sectionColor={Theme.colors.text}
          textColor={Theme.colors.invertedText}
          hasApply={true}
        />
      )}
      <JobOffersSection
        offers={pageContext.jobOffers}
        offersPageSlug={pageContext.offersPageSlug}
      />
    </>
  )
}

JobOfferContent.propTypes = {
  pageContext: PropTypes.object,
}

export default JobOfferContent
