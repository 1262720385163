import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Section from "../../common/section"
import Theme from "../../../theme/theme"
import { TRANSLATIONS } from "../../../utils/translations"
import SectionHeader from "../../common/section-header"
import Link from "../../common/link"
import Arrow from "../../svg/arrow"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  margin-bottom: 0;
  padding-top: 20rem;
  padding-bottom: 20rem;
`

const JobOffers = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12rem;
`

const StyledArrow = styled(Arrow)`
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: rotate(90deg) translate3d(-50%, 0, 0);

  path {
    fill: ${props => props.theme.colors.main};
  }
`

const SingleOffer = styled.li`
  position: relative;
  max-width: 54rem;
  padding: 2rem 10rem 2rem 5rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border: 0.8rem solid ${props => props.theme.colors.text};
  border-radius: 14rem;
  transition: 300ms linear;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    max-width: 64rem;
    margin-bottom: 3rem;
    margin-right: 3rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    max-width: 84rem;
  }

  &:hover {
    background: ${props => props.theme.colors.text};
    color: ${props => props.theme.colors.invertedText};
  }

  h4 {
    font-family: "darker_grotesquebold", sans-serif;
    font-size: 2.8rem;
    line-height: 3rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 4.8rem;
      line-height: 5rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 6.8rem;
      line-height: 7rem;
    }
  }

  p {
    font-size: 2.8rem;
    line-height: 3.2rem;

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 3.6rem;
      line-height: 3.6rem;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`

const JobOffersSection = ({ offers = [], offersPageSlug }) => {
  if (offers.length === 0) return

  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.invertedText}
      threshold={0.4}
      sectionColor={Theme.colors.fourth}
    >
      <SectionHeader>
        {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].otherOffers}
      </SectionHeader>
      <JobOffers>
        {offers.map(offer => (
          <SingleOffer key={offer.contentful_id}>
            <h4>{offer.title}</h4>
            <p>{offer.place}</p>
            <StyledArrow />
            <Link url={`/${offersPageSlug}/${offer.slug}`} />
          </SingleOffer>
        ))}
      </JobOffers>
    </StyledSection>
  )
}

JobOffersSection.propTypes = {
  offers: PropTypes.array,
  offersPageSlug: PropTypes.string,
}

export default JobOffersSection
