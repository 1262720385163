import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import Theme from "../../../theme/theme"
import SectionHeader from "../../common/section-header"
import { BLOCKS } from "@contentful/rich-text-types"
import Arrow from "../../svg/arrow"
import GatsbyImage from "../../common/gatsby-image"
import Shape from "../../svg/shape"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
`

const StyledSectionHeader = styled(SectionHeader)`
  padding-bottom: 7rem;
  padding-top: 11rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    padding-bottom: 11rem;
  }
`

const OfferContent = styled.div`
  font-size: 3rem;
  line-height: 3.8rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.5rem;
    margin-top: 4rem;

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 5rem;
      line-height: 6rem;
    }
  }

  ul {
    list-style-type: none;
  }
`

const SingleItemList = styled.li`
  position: relative;
  margin-top: 2.5rem;
  padding-left: 4.3rem;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2.6rem;
    margin-top: 0.6rem;
    transform: translate3d(0, -50%, 0) rotate(90deg);

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      margin-top: 1rem;
    }

    path {
      fill: ${props => props.theme.colors.main};
    }
  }
`

const ContentContainer = styled.div`
  @media (min-width: ${props => props.theme.devices.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8rem;
  }
`

const OfferImage = styled.div`
  position: relative;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  overflow: hidden;
  border-radius: 60rem;
  transform: translateZ(0);
`

const StyledShape1 = styled(Shape)`
  position: absolute;
  top: 0;
  right: 2rem;
  transform: rotate(-180deg);
  z-index: 5;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: 30rem;
  left: -3rem;
  z-index: 5;
  transform: rotate(90deg);
`

const StyledShape3 = styled(Shape)`
  position: absolute;
  top: 8rem;
  left: 50%;
  z-index: -1;
  transform: rotate(-90deg);
`

const StyledShape4 = styled(Shape)`
  position: absolute;
  top: 20rem;
  right: 0;
  z-index: -1;
`

const StyledShape5 = styled(Shape)`
  position: absolute;
  top: 13rem;
  left: 0;
  z-index: -1;
  width: 80rem;
  height: 80rem;
`

const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      if (!children) return

      return (
        <ul>
          {children.map((child, index) => (
            <SingleItemList key={index} index={index}>
              <Arrow />
              {child.props.children}
            </SingleItemList>
          ))}
        </ul>
      )
    },
  },
}

const ContentSection = ({ jobOffer }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.text}
      threshold={0.4}
    >
      <StyledSectionHeader>{jobOffer.title}</StyledSectionHeader>
      <ContentContainer>
        <OfferContent>
          {documentToReactComponents(
            JSON.parse(jobOffer.description.raw),
            options,
          )}
        </OfferContent>
        <OfferImage>
          <StyledGatsbyImage image={jobOffer.offerImage.gatsbyImageData} />
          <StyledShape1 isMainColor={true} />
          <StyledShape2 isThirdColor={true} />
        </OfferImage>
      </ContentContainer>
      <StyledShape3 isMainColor={true} />
      <StyledShape4 isSecondColor={true} />
      <StyledShape5 isSecondColor={true} type={8} />
    </StyledSection>
  )
}

ContentSection.propTypes = {
  jobOffer: PropTypes.object,
}

export default ContentSection
